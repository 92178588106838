import { ActionIcon, Box, Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Text, Tooltip } from "@mantine/core";
import { IconArrowLeft, IconFileText } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { SettlementApi, SettlementInvoiceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";

export const SettlementDetails = () => {
  const { paymentMethod, overdueStatus, invoiceHistoryLogTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const INV_SETT = usePermission("INV_SETT");
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const params = useParams();
  const location = useLocation();
  const {
    state = {
      refCode: "",
      createdAt: "",
    },
  } = location;
  const [filters] = useState<any>({ settlementId: params?.id });
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { data, isLoading } = useSwr(
    params?.id ? `/inv/settlement/${params?.id}` : null,
    params && params?.id ? async () => await SettlementApi.get(`${params && params?.id}`) : null,
    {
      fallback: [],
    },
  );
  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "details":
          navigate(`/settlement/${record?.invoiceId ?? "test"}/history`, { state: record?.invoice });
          break;

        default:
          break;
      }
    },
    overdueStatus,
    paymentMethod,
    state,
    invoiceHistoryLogTypes,
  });

  const onRespond = async () => {
    setLoading(true);
    try {
      if (params && params?.id) {
        await SettlementApi.respond(`${params.id}`);
        message.success("Үйлдэл амжилттай.");
        navigate(-1);
      }
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  const onSend = async () => {
    setLoading(true);
    try {
      if (params && params?.id) {
        await SettlementApi.send(`${params.id}`);
        message.success("Үйлдэл амжилттай.");
        navigate(-1);
      }
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  if (isLoading) return <LoadingOverlay visible />;
  return (
    <PageLayout
      title={
        <Flex gap="xs">
          <div>
            <ActionIcon onClick={() => navigate(-1)} variant="outline" color="" size={"sm"}>
              <IconArrowLeft />
            </ActionIcon>
          </div>
          <Text>Тооцооны хуудас:</Text>
          <Text c="brand" component="span">
            #{state?.refCode ?? "-"}
          </Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}>
      <Divider h={"md"} />
      {INV_SETT.hasAccess ? (
        <div>
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label="Партнер нэр">
                <Text size="sm">
                  <Text c="brand" component="span">
                    {data?.business?.partner?.refCode} #,
                  </Text>{" "}
                  {data?.business?.partner?.businessName ?? "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label={myBusinessType === "SUPPLIER" ? "Худалдан авагч нэр" : "Нийлүүлэгч нэр"}>
                <Text size="sm">
                  <Text c="brand" component="span">
                    {data?.business?.refCode ?? "-"} #,
                  </Text>{" "}
                  {data?.business?.profileName ?? "-"}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label="Эхлэх өдөр">
                <Text size="sm" c="brand" component="div">
                  {dayjs(state?.createdAt).startOf("month").format("YYYY-MM-DD")}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label="Дуусах өдөр">
                <Text size="sm" c="brand" component="div">
                  {dayjs(state?.createdAt).endOf("month").format("YYYY-MM-DD")}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label="Тооцоо илгээсэн ажилтан">
                <Text size="sm" c="brand" component="div" tt={"capitalize"}>
                  {data?.financeStaff?.lastName ? data?.financeStaff?.lastName[0] + ". " : ""}
                  {data?.financeStaff?.firstName}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 6, md: 6 }}>
              <Input.Wrapper label="Тооцоо илгээж буй огноо">
                <Text size="sm" c="brand" component="div">
                  {dateTimeFormat(`${new Date()}`)}
                </Text>
              </Input.Wrapper>
            </Grid.Col>
            {state?.action !== "DETAILS" && (
              <Grid.Col span={12}>
                <div>
                  {myBusinessType === "SUPPLIER" && (
                    <Button loading={loading} onClick={onSend}>
                      Тооцоо илгээх
                    </Button>
                  )}
                  {myBusinessType === "BUYER" && (
                    <Button loading={loading} onClick={onRespond}>
                      Тооцоо зөвшөөрөх
                    </Button>
                  )}
                </div>
              </Grid.Col>
            )}
          </Grid>

          <Divider my={"lg"} />

          <Table name="settlement.history.list" columns={columns} filters={filters} loadData={(data) => SettlementInvoiceApi.list(data!)} />

          <Group align="center" justify="flex-end">
            <Text ta="end">
              <Text>Эхний үлдэгдэл:</Text>
              <Text c="brand">Тухайн сард нэмэгдсэн:</Text>
              <Text c="brand">Тухайн сард хасагдсан:</Text>
              <Text>Эцсийн үлдэгдэл:</Text>
              <Text>Төлбөрийн үлдэгдэлтэй:</Text>
            </Text>
            <Text ta="end" miw={"120px"}>
              <Text fw={500}>{currencyFormat(data.firstAmount)}</Text>
              <Text fw={500}>{currencyFormat(data.confirmedAmount)} </Text>
              <Text fw={500}>{currencyFormat(data.paidAmount)}</Text>
              <Text fw={500}>{currencyFormat(data.lastAmount)}</Text>
              <Text fw={500}>{data.numberOfInv} нэхэмжлэх </Text>
            </Text>
          </Group>
        </div>
      ) : (
        INV_SETT.accessDenied()
      )}
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  paymentMethod,
  state,
  overdueStatus,
  invoiceHistoryLogTypes,
}: {
  onClick: (key: string, record: any) => void;
  paymentMethod: IReference[];
  state: any;
  overdueStatus: IReference[];
  invoiceHistoryLogTypes: IReference[];
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Дэлгэрэнгүй">
        <ActionIcon color="" onClick={() => onClick("details", record)}>
          <IconFileText />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "Нэхэмжлэх дугаар",
    render: (record) => (
      <Text w="max-content" size="sm">
        #{record?.invoice?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: (
      <Text ta="center" fw={700} fz={14} style={{ width: "100%" }}>
        Төрөл
      </Text>
    ),
    width: "100px",
    render: (record) => (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <div>
          {Tag(
            invoiceHistoryLogTypes?.find((item: IReference) => item.code === record.logType)?.color ?? "blue",
            invoiceHistoryLogTypes?.find((item: IReference) => item.code === record.logType)?.name ?? record?.logType ?? "-",
          )}
        </div>
      </Box>
    ),
  },
  {
    title: (
      <Text ta="end" style={{ whiteSpace: "nowrap", width: "100%" }} fz={14} fw={700}>
        Нэмэгдсэн
      </Text>
    ),
    width: "180px",
    render: (record) => (
      <Group align="right">
        {record?.logType === "CONFIRMED" ? (
          <Text c="green" ta="end" style={{ whiteSpace: "nowrap", width: "100%" }} size="sm">
            {currencyFormat(record?.invoice?.confirmedAmount)}
          </Text>
        ) : (
          <Text ta="end" style={{ width: "100%" }}>
            -
          </Text>
        )}
      </Group>
    ),
  },
  {
    title: (
      <Text ta="end" style={{ whiteSpace: "nowrap", width: "100%" }} fz={14} fw={700}>
        Хасагдсан
      </Text>
    ),
    width: "180px",
    render: (record) => (
      <Group align="right">
        {record?.logType === "PAYMENT" ? (
          <Text c="red" ta="end" style={{ whiteSpace: "nowrap" }} w="100%" size="sm">
            {currencyFormat(record?.trxAmount)}
          </Text>
        ) : (
          <Text ta="end" style={{ width: "100%" }}>
            -
          </Text>
        )}
      </Group>
    ),
  },

  {
    title: "Хугацаа хэтрэлт",
    width: "150px",
    render: (record) => (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <div>
          {Tag(
            overdueStatus.find((item: any) => item.code === record?.invoice?.overdueStatus)?.color ?? "gray",
            overdueStatus.find((item: any) => item.code === record?.invoice?.overdueStatus)?.name ?? "-",
          )}
        </div>
      </Box>
    ),
  },
  {
    title: "Үлдсэн хоног",
    width: "100px",
    render: (record) => (
      <Text w="100%" ta="center" size="sm">
        {dayjs(record?.invoice?.paymentDate).diff(new Date(), "days")}
      </Text>
    ),
  },
  {
    title: "Үүссэн огноо",
    width: "100px",
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(record?.createdAt ?? "-")}
      </Text>
    ),
  },
  {
    title: "Төлөх ёстой огноо",
    render: (record) => (
      <Text w="max-content" size="sm">
        {dateTimeFormat(record?.invoice?.paymentDate ?? "-")}
      </Text>
    ),
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нэхэмжлэх удирдлага",
  },
  {
    label: "Тооцоо нийлэх",
  },
  {
    label: "Тооцооны хуудас",
  },
];
