import { ActionIcon, Anchor, Avatar, Flex, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconPhoto } from "@tabler/icons-react";
import { useRef } from "react";
import { useNavigate } from "react-router";
import { NetworkSettlementApi } from "../../apis";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { currencyFormat } from "../../utils";

export const CustormerBillingSupplier = ({ filters }: { filters: any }) => {
  const navigate = useNavigate();
  const tableRef = useRef<ITableRef>(null);

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "details":
          navigate(`/customer-billing/${record?.id || "test"}`, {
            state: {
              refCode: record.refCode,
              profileName: record.profileName,
            },
          });
          break;

        default:
          break;
      }
    },
  });

  return (
    <div>
      <Table ref={tableRef} name="requests.list.SUPPLIER" columns={columns} filters={filters} loadData={(data) => NetworkSettlementApi.network(data!)} />
    </div>
  );
};

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Дэлгэрэнгүй">
        <ActionIcon color="" onClick={() => onClick("details", record)} variant="light">
          <IconFileText />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "ТТД",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.regNumber}
      </Text>
    ),
  },
  {
    title: "Худалдан авагч партнер",
    render: (record) =>
      record?.partner ? (
        <Flex align="center" gap="sm">
          <Avatar src={record?.partner?.logo} radius="xs">
            <IconPhoto />
          </Avatar>
          <div>
            <Anchor size="sm" w="max-content">
              #{record?.partner.refCode}
            </Anchor>
            <Text size="sm" w="max-content">
              {record?.partner.businessName}{" "}
            </Text>
          </div>
        </Flex>
      ) : (
        "-"
      ),
  },
  {
    title: "Худалдан авагч бизнес",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.logo} radius="xs">
          <IconPhoto />
        </Avatar>
        <div>
          <Anchor size="sm" w="max-content">
            #{record?.refCode}
          </Anchor>
          <Text size="sm" w="max-content">
            {record?.profileName}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Худалдан авагч санхүү",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.finStaff?.avatar}>
          <IconPhoto />
        </Avatar>
        <div>
          <Text tt="capitalize" size="sm" w="max-content">
            {record?.finStaff?.lastName ? record?.finStaff?.lastName[0] + "." : ""}
            {record?.finStaff?.firstName}
          </Text>
          <Text c="gray.7" size="sm" w="max-content">
            {record?.finStaff?.email}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Үлдэгдэлтэй нэхэмжлэх",
    render: (record) => (
      <Text ta="center" size="sm">
        {record?.invoicesCount ?? "-"}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэхийн үлдэгдэл дүн",
    render: (record) => (
      <Text c="brand" ta="end" size="sm">
        {currencyFormat(record?.invoicesAmount)}
      </Text>
    ),
  },
  {
    title: "Хугацаа хэтэрсэн нэхэмжлэх",
    render: (record) => (
      <Text ta="center" size="sm">
        {record?.overdueInvoicesCount ?? "-"}
      </Text>
    ),
  },
  {
    title: "Хугацаа хэтэрсэн үлдэгдэл дүн",
    render: (record) => (
      <Text c="brand" ta="end" size="sm">
        {currencyFormat(record?.overdueInvoicesAmount)}
      </Text>
    ),
  },
];
