import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const ebarimt = async (id: string) => {
  return httpRequest.post(`/inv/ebarimt`, { invoiceId: id });
};

export const ebarimtReturn = async (id: string) => {
  return httpRequest.del(`/inv/ebarimt/${id}`);
};
