import { ActionIcon, Anchor, Avatar, Box, Button, Drawer, Flex, Grid, Input, Paper, SegmentedControl, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconFileText, IconFileTypePdf, IconListSearch, IconPhoto, IconPrinter } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { CommingSoon } from "../../components/layout/comming-soon";
import { usePermission } from "../../components/permission";
import { RegisterPayment } from "../../components/register-payment/register-payment";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { dateTimeFormat } from "../../utils/date";

export const SalesInvoiceList = () => {
  const breadcrumbs = useBreadcrumb();
  const INV_LIST = usePermission("INV_LIST");
  const INV_NOTE_PAY = usePermission("INV_NOTE_PAY");

  const { invoiceStatus = [], invoicePaymentStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const { myBusinessType } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<any[]>([]);
  const [load, setLoad] = useState<string[]>([]);
  const tableRef = useRef<ITableRef>(null);

  const navigate = useNavigate();

  const [filters, setFilters] = useState<{ regNumber: null | string; status: string }>({
    regNumber: null,
    status: "",
  });

  const Pdf = async (id: string) => {
    let res = await InvoiceApi.pdf(id);
    setLoad([...load.filter((item: string) => item !== id)]);
    window.open(res);
  };

  const columns = useHeader({
    myBusinessType,
    invoicePaymentStatus: invoicePaymentStatus,
    invoiceStatus: invoiceStatus,
    load,
    setLoad,
    onClick: (key, record) => {
      if (key === "edit") navigate(`/sales-invoice/${record?.id}`);
      if (key === "details") navigate(`/sales-invoice/${record?.id}/details`);
      if (key === "pdf") {
        setLoad([...load, `${record.id}`]);
        Pdf(`${record.id}`);
      }
    },
  });

  const excludedStatuses = ["CANCELED", "CLOSED", "RETURNED"];

  return (
    <PageLayout
      title="Нээлттэй нэхэмжлэх"
      subTitle="Төлбөрийн үлдэгдэлтэй нэхэмжлэлүүд"
      breadcrumb={breadcrumbs}
      extra={[
        myBusinessType === "SUPPLIER" && (
          <Link to="/sales-invoice/new" key={0} hidden={!INV_LIST.isCreate}>
            <Button size="sm" key={0}>
              Шинэ нэхэмжлэх
            </Button>
          </Link>
        ),
        <Button
          hidden={!INV_NOTE_PAY.hasAccess}
          onClick={() => {
            setAction(["payment_register"]);
          }}
          px={"xs"}
          key={2}>
          Төлөлт бүртгэх
        </Button>,
        <CommingSoon visible={true} position="top" key={1} width={200}>
          <Button variant="light" px={"xs"}>
            <IconPrinter />
          </Button>
        </CommingSoon>,
      ]}>
      <>
        <Box pb={"xs"} style={{ overflowX: "auto" }}>
          <SegmentedControl
            key={1}
            onChange={(e) => setFilters({ ...filters, status: e })}
            value={filters.status}
            data={[{ name: "Бүгд", code: "" }, ...invoiceStatus]
              ?.filter((item) => !excludedStatuses.includes(item.code))
              .map((item: any, index: number) => {
                return {
                  value: item?.code,
                  label: (
                    <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
                      {index !== 0 && (
                        <Box
                          style={{
                            width: 8,
                            height: 8,
                            borderRadius: "100%",
                            background: `${item?.color}`,
                          }}
                        />
                      )}
                      {item?.name ?? "-"}
                    </Box>
                  ),
                };
              })}
          />
        </Box>
        <Paper>
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
              <Input.Wrapper label="Татвар төлөгчийн дугаараар">
                <Input
                  value={`${filters?.regNumber ?? ""}`}
                  onChange={(e) => {
                    setFilters({ ...filters, regNumber: e.currentTarget.value });
                  }}
                  placeholder="Татвар төлөгчийн дугаараар"
                  rightSection={<IconListSearch color="gray" size={"1.2rem"} />}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
        </Paper>
        <br />
        {INV_LIST.isView ? (
          <Table ref={tableRef} name="sales.invoice.list.sent" columns={columns} filters={filters} loadData={(data) => InvoiceApi.sent(data!)} />
        ) : (
          INV_LIST.accessDenied()
        )}
      </>
      <Drawer
        size={"70rem"}
        position="right"
        opened={action[0] === "payment_register"}
        onClose={() => {
          setAction([]);
        }}
        withCloseButton={false}
        title={false}
        padding={0}>
        <RegisterPayment
          reload={async () => {
            tableRef.current?.reload();
          }}
          action={action}
          setAction={setAction}
        />
      </Drawer>
    </PageLayout>
  );
};

const useHeader = ({
  onClick,
  myBusinessType = "",
  invoicePaymentStatus = [],
  invoiceStatus = [],
  load = [],
  setLoad,
}: {
  onClick: (key: string, record: IItem) => void;
  myBusinessType: string;
  invoicePaymentStatus: IReference[];
  invoiceStatus: IReference[];
  load: string[];
  setLoad: any;
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Flex gap="sm" align="center">
          <Tooltip label="Дэлгэрэнгүй">
            <ActionIcon onClick={() => onClick("details", record)} variant="light" color="">
              <IconFileText />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="PDF">
            <ActionIcon loading={load.some((item: string) => item === record.id)} onClick={() => onClick("pdf", record)} variant="light" color="indigo">
              <IconFileTypePdf />
            </ActionIcon>
          </Tooltip>
          {record.invoiceStatus === "DRAFT" && (
            <Tooltip label="Засах">
              <ActionIcon onClick={() => onClick("edit", record)} variant="light" color="green" disabled={record.invoiceStatus !== "DRAFT"}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Нэхэмжлэх дугаар",
    dataIndex: "refCode",
    render: (record) => {
      return <Text size="sm">#{record?.refCode}</Text>;
    },
  },
  {
    title: "Худалдан авагч бизнес",
    dataIndex: "category",
    render: (record) =>
      record?.receiverBusiness ? (
        <Box>
          <Anchor onClick={() => onClick("buyer", record)}>
            <Text size="sm" fw={500} c="brand" w="max-content">
              #{record?.receiverBusiness?.refCode ?? "-"}
            </Text>
          </Anchor>
          <Text c="dimmed" size="sm" w="max-content">
            {record?.receiverBusiness?.profileName ?? "-"}
          </Text>
        </Box>
      ) : (
        "-"
      ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "itemStatus",
    render: (record) => (
      <Flex align="center" gap="sm">
        <Avatar src={record?.receiverBusiness?.logo} radius="xs">
          <IconPhoto />
        </Avatar>
        <div>
          <Text size="sm" w="max-content">
            #{record?.receiverBusiness?.partner?.refCode ?? "-"}
          </Text>
          <Text size="sm" c="gray" w="max-content">
            {record?.receiverBusiness?.partner?.businessName ?? "-"}
          </Text>
        </div>
      </Flex>
    ),
  },
  {
    title: "Баталсан дүн",
    dataIndex: "confirmedAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="orange.5" size="sm">
        {currencyFormat(record?.confirmedAmount ?? 0)}
      </Text>
    ),
  },
  {
    title: "Төлсөн дүн",
    dataIndex: "paidAmount",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="green.5" size="sm">
        {record.paidAmount ? currencyFormat(record.paidAmount ?? 0) : "-"}
      </Text>
    ),
  },
  {
    title: "Төлбөрийн үлдэгдэл",
    dataIndex: "amountToPay",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" c="gray.6" size="sm">
        {currencyFormat(record?.amountToPay ?? 0)}
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэхийн төлөв",
    dataIndex: "description",
    render: (record) => {
      return (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {Tag(
            invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.color ?? "",
            invoiceStatus.find((item: IReference) => item.code === record.invoiceStatus)?.name ?? record.invoiceStatus,
          )}
        </Box>
      );
    },
  },
  {
    title: "Төлбөрийн статус",
    dataIndex: "description",
    render: (record) => {
      return record.invoiceStatus !== "REJECTED" ? (
        Tag(
          invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.color ?? "",
          invoicePaymentStatus.find((item: IReference) => item.code === record.paymentStatus)?.name ?? record.paymentStatus,
        )
      ) : (
        <Text size="sm">-</Text>
      );
    },
  },
  {
    title: "Үлдсэн хугацаа",
    dataIndex: "description",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }} ta="end" size="sm">
        {record?.remainingDays ?? "-"} хоног
      </Text>
    ),
  },
  {
    title: "Нэхэмжлэх төлөх огноо",
    dataIndex: "description",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.paymentDate ? dateTimeFormat(record?.paymentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Баталсан огноо",
    dataIndex: "description",
    render: (record) => (
      <Text w={"max-content"} size="sm">
        {record?.confirmedDate ? dateTimeFormat(record?.confirmedDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Баталсан ажилтан",
    dataIndex: "confirmedUser",
    render: (record) => {
      if (record?.confirmedUser)
        return (
          <Flex gap={10} align="center" w={"max-content"}>
            <Avatar component="a" src={record?.confirmedUser?.avatar} />
            <Text size="sm" tt={"capitalize"}>
              {record?.confirmedUser?.lastName ? `${record?.confirmedUser?.lastName[0]}.` : ""}
              {record?.confirmedUser?.firstName ?? "-"}
            </Text>
          </Flex>
        );
      return "-";
    },
  },
  {
    title: "Илгээсэн огноо",
    dataIndex: "description",
    render: (record) => (
      <Text w="max-content" size="sm">
        {record?.sentDate ? dateTimeFormat(record?.sentDate) : "-"}
      </Text>
    ),
  },
  {
    title: "Илгээсэн ажилтан",
    dataIndex: "description",
    width: "520px",
    render: (record) => (
      <Flex gap={10} align="center" w={"max-content"}>
        <Avatar component="a" src={record?.senderUser?.avatar} />
        <Text size="sm" tt={"capitalize"}>
          {record?.senderUser?.lastName ? `${record?.senderUser?.lastName[0]}.` : ""}
          {record?.senderUser?.firstName}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Бор.Зах дугаар",
    dataIndex: "salesOrder.refCode",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.orderSalesCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Худ.авалт Зах.дугаар",
    dataIndex: "purchaseOrder.refCode",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.orderPurchaseCode ?? "-"}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нээлттэй нэхэмжлэх",
  },
];
