import { Avatar, Button, Center, Divider, Flex, Grid, Group, Input, Paper, Text, Textarea } from "@mantine/core";
import { IconBarcodeOff, IconCreditCard } from "@tabler/icons-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { InvoiceApi } from "../../apis";
import { IAccounts } from "../../interfaces/IAccounts";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";
import { message } from "../../utils/Message";
import { dateTimeFormat } from "../../utils/date";
import { Form } from "../form";
import { NumberCurrencyField } from "../form/number-currency-field";
import { SelectField } from "../form/select-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { Tag } from "../tags";
import { FileUpload } from "../upload/upload-file";

const schema = yup.object({
  amount: yup
    .number()
    .min(0, "0-с их дүн оруулна уу!")
    .max(1000000000, "1,000,000,000-с бага дүн оруулна уу!")
    .typeError("Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
  method: yup.string().required("Заавал бөглөнө!").nullable(),
  addInfo: yup.string().max(255, "Хэт урт байна.").required("Заавал бөглөнө!").nullable(),
  creditAccountId: yup
    .string()
    .when("method", {
      is: (val: any) => {
        return val !== "QPAY";
      },
      then: (schema) => schema.required("Заавал бөглөнө!").nullable(),
    })
    .optional(),
});

type IForm = {
  id: string;
  refCode: string;

  invoiceId: string;
  invoiceRefCode: string;
  receiverBusinessId: string;
  method: string;
  amount: string;
  description: string;

  creditAccountId: string;
  creditAccountBank: string;
  creditAccountName: string;
  creditAccountNumber: string;
  creditAccountCurrency: string;

  debitAccountId: string;
  debitAccountBank: string;
  debitAccountName: string;
  debitAccountNumber: string;
  debitAccountCurrency: string;

  addInfo: string;
  files: string[];

  senderBusinessId: string;
};

export function BuyerInvoicePayForm({ action, setAction, setModalOpen, onSuccess }: { action: any; setAction: any; setModalOpen?: any; onSuccess?: any }) {
  const { invoiceStatus, invoicePaymentStatus } = useSelector((state: { general: IGeneral }) => state.general);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedAccount, setSelectedAccount] = useState<IAccounts | undefined>();
  const [loading, setloading] = useState<boolean>(false);
  const [data] = useState<IForm | any>({
    amount: undefined,
    method: undefined,
    creditAccountId: undefined,
    addInfo: undefined,
    files: undefined,
    ...(action[1] && action[1]),
    debitAccountBank: action[1] && action[1].senderAcc?.bankName,
    debitAccountCurrency: action[1] && action[1].senderAcc?.currency,
    debitAccountId: action[1] && action[1].senderAcc?.id,
    debitAccountName: action[1] && action[1].senderAcc?.name,
    debitAccountNumber: action[1] && action[1].senderAcc?.number,
  });

  const { bankAccounts } = useSelector((state: { general: IGeneral }) => state.general);
  const [qpay, setQpay] = useState<any>({ success: false });

  const onSubmit = async (values: IForm) => {
    setloading(true);
    try {
      let res = await InvoiceApi.pay({
        invoiceId: values.id,
        invoiceRefCode: values.refCode,
        receiverBusinessId: values.senderBusinessId,
        method: values.method,
        amount: values.amount,
        description: values.refCode,

        creditAccountId: values.creditAccountId,
        creditAccountBank: values.creditAccountBank,
        creditAccountName: values.creditAccountName,
        creditAccountNumber: values.creditAccountNumber,
        creditAccountCurrency: values.creditAccountCurrency,

        debitAccountId: values.debitAccountId,
        debitAccountBank: values.debitAccountBank,
        debitAccountName: values.debitAccountName,
        debitAccountNumber: values.debitAccountNumber,
        debitAccountCurrency: values.debitAccountCurrency,

        addInfo: values.addInfo,
        files: values.files ? [values.files] : [],
      });

      if (values.method === "QPAY") {
        setQpay({ ...res, success: true });
      } else {
        if (res?.url) {
          window.open(res?.url, "_blank", "width=800,height=600,left=200,top=100");
        }
        setAction([]);
        setModalOpen(false);
        onSuccess();
        message.success(`Нэхэмжлэл амжилттай төлөгдлөө. ${dateTimeFormat(new Date())}`);
      }
    } catch (error: any) {
      message.error(error.message);
    }
    setloading(false);
  };

  return (
    <Paper px="lg">
      <FormLayout title="">
        <Form validationSchema={schema} initialValues={data} onSubmit={onSubmit}>
          {({ values, setFieldValue, errors }) => {
            return (
              <>
                <Grid>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Нэхэмжлэх дугаар, статус:" required>
                      <Text c="brand" fz="sm" fw={500}>
                        <Flex align="center" gap={8}>
                          # {values?.refCode || "-"}
                          {Tag(
                            invoiceStatus.find((item: any) => item.code === values.invoiceStatus)?.color || "gray",
                            invoiceStatus.find((item: any) => item.code === values.invoiceStatus)?.name || "-",
                          )}
                        </Flex>
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Төлбөр хүлээн авагч:" required>
                      <Text c="brand" fz="sm" fw={500}>
                        {values?.senderBusiness?.regNumber || "-"}, {values?.senderBusiness?.partner?.businessName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Нийлүүлэгч бизнес:" required>
                      <Text c="brand" fz="sm" fw={500}>
                        {values?.senderBusiness?.profileName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Нэхэмжлэх төлөх огноо:" required>
                      <Text c="brand" fz="sm" fw={500}>
                        {values?.confirmedDate ? dateTimeFormat(values?.confirmedDate) : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Нэхэмжлэхийн үлдэгдэл:" required>
                      <Text c="brand" fz="sm" fw={500}>
                        {values?.amountToPay ? currencyFormat(values.amountToPay) : "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                    <Input.Wrapper label="Төлбөрийн статус, төлөх дүн:" required>
                      <Flex align="center" gap={8}>
                        <Text c="brand" fz="sm" fw={500}>
                          {values.confirmedAmount ? currencyFormat(values.confirmedAmount) : "-"}{" "}
                        </Text>
                        {Tag(
                          invoicePaymentStatus.find((item: IReference) => item.code === values.paymentStatus)?.color || "gray",
                          invoicePaymentStatus.find((item: IReference) => item.code === values.paymentStatus)?.name || "",
                        )}
                      </Flex>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <Divider h={"lg"} mt="md" />
                    <Text fz={"lg"} fw={600}>
                      Төлбөрийн мэдээлэл
                    </Text>
                    <Text fz="sm" c="dimmed">
                      Нэхэмжлэхийн төлбөр төлөх мэдээлэл
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Group justify="flex-end" align="center" h={"36px"}>
                      <Text size="sm" c="gray.9" fw={500}>
                        Төлбөрийн хэрэгсэл:
                      </Text>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <SelectField
                      searchable
                      clearable
                      placeholder="Сонгох"
                      name="method"
                      options={[
                        {
                          code: "B2B",
                          name: "B2B тооцооны данснаас",
                        },
                        {
                          code: "QPAY",
                          name: "QPay",
                        },
                      ]?.map((item: IReference | any) => {
                        return {
                          value: item.code,
                          label: item.name,
                        };
                      })}
                      onChange={(e) => {
                        if (e === "QPAY") setFieldValue("amount", values?.amountToPay || 0);
                        else setFieldValue("amount", undefined);
                      }}
                      required
                      disabled={qpay.success}
                    />
                  </Grid.Col>
                  {!qpay.success ? (
                    <>
                      {values.method !== "QPAY" ? (
                        <>
                          <Grid.Col span={4}></Grid.Col>
                          <Grid.Col span={4}>
                            <Group justify="flex-end" align="center" h={"36px"}>
                              <Text size="sm" c="gray.9" fw={500}>
                                Шилжүүлэх данс:
                              </Text>
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={4}>
                            <SelectField
                              searchable
                              clearable
                              placeholder="Сонгох"
                              name="creditAccountId"
                              onChange={(e) => {
                                setSelectedAccount(bankAccounts?.find((item: any) => item.id === e));
                                let selectedData = bankAccounts?.find((item: any) => item.id === e);
                                setFieldValue("creditAccountBank", selectedData?.bankName);
                                setFieldValue("creditAccountName", selectedData?.name);
                                setFieldValue("creditAccountNumber", selectedData?.number);
                                setFieldValue("creditAccountCurrency", selectedData?.currency);
                              }}
                              options={bankAccounts?.map((item: IAccounts) => {
                                return {
                                  value: item.id,
                                  label: `${item.shortName} / ${item.number}`,
                                  disabled: !item.isEnabled,
                                };
                              })}
                              required
                            />
                          </Grid.Col>
                        </>
                      ) : null}

                      <Grid.Col span={4}></Grid.Col>
                      <Grid.Col span={4}>
                        <Group justify="flex-end" align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Хүлээн авах данс:
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Input value={`${values?.senderAcc?.number}, ${values?.senderAcc?.bankName}`} readOnly placeholder="Автоматаар гарна" required />
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Group align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            {values?.senderBusiness?.regNumber}, {values?.senderAcc?.name}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Group justify="flex-end" align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Гүйлгээний утга:
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Textarea readOnly placeholder="Автоматаар гарна" value={values?.refCode} required disabled />
                      </Grid.Col>
                      <Grid.Col span={4}></Grid.Col>
                      <Grid.Col span={4}>
                        <Group justify="flex-end" align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Төлөх дүнг оруулна уу:
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <NumberCurrencyField placeholder="00.00" name="amount" disabled={values.method === "QPAY"} required />
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Group align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Үлдэх төлбөр:{" "}
                            {values.amount ? currencyFormat((values.amountToPay || 0) - values.amount || 0) : currencyFormat(values.amountToPay || 0)}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Group justify="flex-end" align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Нэмэлт тэмдэглэл:
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <TextareaField placeholder="Нэмэлт тэмдэглэл" name="addInfo" required />
                      </Grid.Col>
                      <Grid.Col span={4}></Grid.Col>
                      <Grid.Col span={4}>
                        <Group justify="flex-end" align="center" h={"36px"}>
                          <Text size="sm" c="gray.9" fw={500}>
                            Нэмэлт файл хавсаргах:
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <FileUpload
                          value={values?.files}
                          error={errors?.files && !values?.files}
                          onChange={(e) => setFieldValue("files", e?.url)}
                          name="files"
                          placeholder={values?.files ? `файл: ${values?.files?.slice(66, 96)}` : "Хавсралт"}
                        />
                      </Grid.Col>
                      <Grid.Col span={4}></Grid.Col>
                    </>
                  ) : (
                    <>
                      <Grid.Col span={12}>
                        <Center>
                          <Avatar size={320} radius={0} src={"data:image/jpeg;base64," + qpay?.qr_image || ""} typeof="base64">
                            <IconBarcodeOff />
                          </Avatar>
                        </Center>
                        <Center>
                          <Text mt="md" maw={320} ta="center">
                            Та доорх QR кодыг банк апп-р уншуулах эсвэл банкаа сонгож АПП-р төлнө уу.
                          </Text>
                        </Center>
                      </Grid.Col>
                    </>
                  )}
                  <Divider h={"lg"} mt="md" />
                  <Grid.Col span={12}>
                    <Divider h="lg" />
                    <Group justify="flex-end">
                      {!qpay.success ? (
                        <Button loading={loading} leftSection={<IconCreditCard />} type="submit">
                          {values.method === "QPAY" ? "Төлөх" : "Төлбөр батлах"}
                        </Button>
                      ) : (
                        <Button
                          loading={loading}
                          onClick={() => {
                            setAction([]);
                          }}>
                          Болсон
                        </Button>
                      )}
                    </Group>
                  </Grid.Col>
                </Grid>
              </>
            );
          }}
        </Form>
      </FormLayout>
    </Paper>
  );
}
