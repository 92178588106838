import { Popover, Text } from "@mantine/core";

type PopoverTextProps = {
  children: string;
  withArrow?: boolean;
  position?: "top" | "left" | "bottom" | "right";
};

export function PopoverText({ children, withArrow = true, position = "top" }: PopoverTextProps) {
  const shouldTruncate = children.length > 18;
  const truncatedText = shouldTruncate ? `${children.substring(0, 18)}...` : children;

  return shouldTruncate ? (
    <Popover width="max-content" position={position} withArrow={withArrow} shadow="sm">
      <Popover.Target>
        <Text component="a" size="sm" w="max-content">
          {truncatedText}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm" w="max-content">
          {children}
        </Text>
      </Popover.Dropdown>
    </Popover>
  ) : (
    <Text size="sm" w="max-content">
      {children}
    </Text>
  );
}
